import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import iosSvg from "../images/ic_app_store.svg";
import androidSvg from "../images/ic_google_play.svg";

export default function Deeplink() {
  const [loading, setLoading] = useState(true);
  const [device, setDevice] = useState("UNKNOWN");
  function getMobileOperatingSystem() {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Android detection
    if (/android/i.test(userAgent)) {
      setDevice("ANDROID");
      return "ANDROID";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setDevice("IOS");
      return "IOS";
    }
    // Windows Phone must come first because its UA also contains "Android"
    // if (/windows phone/i.test(userAgent)) {
    // }
    else {
      setDevice("UNKNOWN");
      return "UNKNOWN";
    }
  }
  useEffect(() => {
    let device = getMobileOperatingSystem();
    console.log("device detecting", device);
    const reloadTimeForDeepLink = setTimeout(() => {
      setLoading(false);
    }, 10000);

    return () => {
      clearTimeout(reloadTimeForDeepLink);
    };
  }, []);

  return (
    <>
      {/* <div style={{ height: "100vh", width: "100vw" }}> */}
      <p className="apnadharm_info">
        The primary goal of Apna Dharm is to meet the religious requirements of
        the Jain community by offering the necessary services. Our database
        contains around nine thousand temples, guashalas, and ayurvedic
        aushadhalayas located all throughout India. The majority of sacred Jain
        literature and bhajans have been digitalized by us, and we also provide
        all community members with pertinent and carefully chosen audio and
        video information. Members of our community can also make donations to
        an 80G trust, where all accounting is done online. You will receive
        digital donation receipts as soon as you make a donation. The app is
        multilingual. Our primary objective is to link religious organizations
        with the user community on a digital platform by offering them digital
        services.
      </p>
      <div className="d-flex justify-content-center align-items-center">
        {loading ? (
          <Spinner className="spinnerStyle" animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <>
            {device === "IOS" && (
              <a
                className="m-2"
                href="https://apps.apple.com/in/app/apna-dharm/id6470433854" // replace URL with the original app URL if needed
              >
                <img className="deepLink" alt="App Store Icon" src={iosSvg} />
              </a>
            )}

            {device === "ANDROID" && (
              <a
                className="m-2"
                href="https://play.google.com/store/apps/details?id=com.apnamandir" // replace URL with the original app URL if needed
              >
                <img
                  className="deepLink"
                  alt="Google Play Store Icon"
                  src={androidSvg}
                />
              </a>
            )}
            {device === "UNKNOWN" ? (
              <>
                <a
                  className="m-2"
                  href="https://play.google.com/store/apps/details?id=com.apnamandir"
                >
                  {" "}
                  {/* replace url with original app url  */}
                  <img
                    className="deepLink"
                    alt="Google Playstore Icon"
                    src={androidSvg}
                  />
                </a>
                <a
                  className="m-2"
                  href="https://apps.apple.com/in/app/apna-dharm/id6470433854"
                >
                  {" "}
                  {/* replace url with original app url  */}
                  <img className="deepLink" alt="App Store Icon" src={iosSvg} />
                </a>
              </>
            ) : null}
          </>
        )}
      </div>
      {/* </div> */}
    </>
  );
}

{
  /* <button
onClick={() => deepLinkFunc()}
className="btn btn-primary"
style={{
  backgroundColor: "#5d707e",
  border: 0,
  padding: "10px",
  width: "125px",
}}
>
Get App
</button> */
}
