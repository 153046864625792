import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import logo from "./images/main-logo.png";

import "./index.css";
import Deeplink from "./pages/DeepLink";

function App() {
  return (
    <>
      <div className="container header w-100 d-flex justify-content-center  ">
        <img
          className={`apnadharmLogoImage`}
          src={logo}
          alt="Apna Dharm Logo"
        />
      </div>

      <div
        className={` ${
          window.location.pathname == "/" ? "container mt-4" : "container mt-4"
        }`}
      >
        <Router>
          <Switch>
            <Route path="/">
              <Deeplink />
            </Route>
            <Route path="/:module">
              <Deeplink />
            </Route>
            <Route path="/:module/:moduleId">
              <Deeplink />
            </Route>
          </Switch>
        </Router>
      </div>
    </>
  );
}
{
  /* <Route exact path="/" component={Deeplink} />
<Redirect from="*" to="/" /> */
}

export default App;
